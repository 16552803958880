import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const USMDMidlothianLandingPage = () => (
  <PhysicianLandingPage
    physician="Dr. Modi"
    institution="USMD Midlothian"
    referralCode="MODI"
  />
)

export default USMDMidlothianLandingPage
